import React, { Component } from "react"

/* Constants */
import { env } from '../constants'

/* Moment */
import moment from 'moment'
import 'moment/locale/ru'

/* Moment Settings */
moment.locale("ru")



/* Component Card */
class Card extends Component {

    /* Draw Cards */
    _cards = () => {

        const { cards } = this.props

        if (cards && Array.isArray(cards) && cards.length > 0) {
            return (
                <div className="cards--list">
                    {cards.map((card, index) =>
                        <div className={`cards--element ${card.status === "hidden" ? "hidden" : "default"}`} key={`${index}`}>
                            <img src={`${env.mediapoint}/cards/${card.image}`} alt="Card" />
                            <div className="cards--element-datetime">
                                <div className="cards--element-time">{moment(card.createdAt).format("HH:mm")}</div>
                                <div className="cards--element-date">{moment(card.createdAt).format("Do MMM")}</div>
                            </div>
                            {card.status === "hidden" && <div className="cards--element-status hidden"><img src="/images/game/close.png" alt="Close" /></div>}
                            {(card.status === "fifth" || card.status === "sixth") && <div className="cards--element-status yellow">+1</div>}
                            {card.status === "exchange" && <div className="cards--element-status yellow"><img src="/images/game/exchange.png" alt="Close" /></div>}
                            {card.status === "buy" && <div className="cards--element-status purple">+1</div>}
                        </div>
                    )}
                </div>
            )
        }
        else {
            return <div className="cards--empty">Пусто</div>
        }
    }

    render = () => {

        const { title, text, result } = this.props

        return (
            <div className="cards--box">

                <div className="cards-head">
                    {title && <div className="cards--title">{title}</div>}
                    {text && <div className="cards--text">{text}</div>}
                </div>

                {result &&
                    <div className="cards--result">
                        <div className="cards--result-title">{result.result}</div>
                        <div className="cards--result-text">{result.multiplier} : 1</div>
                    </div>
                }

                {this._cards()}

            </div>
        )
    }

}

export default Card