import React, { Component } from "react"

/* Widgets */
import { Authorization, Layout } from './widgets'

/* JWT */
import { isExpired } from "react-jwt"

/* REST API */
import { refresh as refreshToken } from './api/Authorization'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Cookie */
import Cookies from "js-cookie"

/* Modal */
import Modal from "react-modal"

/* DEFAULT CONFIG */
Modal.setAppElement("#root")


/* Entry point */
class App extends Component {

    constructor() {
        super()

        const token = localStorage.getItem("token")
        this.state = {
            token,
            loading: false
        }
    }

    componentDidMount = () => {

        const { token } = this.state
        const refresh = Cookies.get("refresh")

        if (!refresh || isExpired(refresh)) {
            return
        }

        if (!token || isExpired(token)) {

            this.setState({ loading: true })

            refreshToken({ refresh }).then(response => {
                if (response.status === 200) {
                    this.setState({ token: response.data.accessToken })
                    localStorage.setItem("token", response.data.accessToken)
                    Cookies.set("refresh", response.data.refreshToken, { expires: 1 })
                }
            }).finally(() => {
                this.setState({ loading: false })
            })

        }

    }

    render = () => {

        const { token, loading } = this.state

        if (loading) {
            return (
                <div className="loading">
                    <LoadingOutlined />
                </div>
            )
        }

        if (!token || isExpired(token)) {
            return <Authorization setToken={token => this.setState({ token })} />
        }

        return <Layout setToken={token => this.setState({ token })} />
    }

}

export default App