import React, { Component } from "react"

/* REST API */
import { currency, removeCurrency as remove, createCurrency as create, editCurrency as edit } from '../../api/Table'

/* Widgets */
import Layout from './Layout'

/* Helpers */
import { utils } from '../../helpers'

/* Sweet alert */
import Swal from "sweetalert2"




/* Widget Currency */
class Currency extends Component {

    constructor() {
        super()

        this.state = {
            status: 'loading',
            data: [],
            currencies: []
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Dealers Data */
    load = (reload = false) => {

        /* Fields */
        const { uid } = this.props

        /* Check Params */
        if (!uid) {
            this.setState({ status: 'error', data: [] })
            return
        }

        /* Refresh the page if necessary */
        if (reload) {
            this.setState({ status: 'loading' })
        }

        /* START REST API */
        currency({ uid }).then(response => {
            if (response.status) {
                this.setState({ status: "ok", data: response.data.list, currencies: response.data.currencies })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [] })
            }
            else {
                this.setState({ status: "error", data: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [] })
        })

    }


    /* Remove Action */
    remove = id => {

        /* Fields */
        const { uid } = this.props
        const { data } = this.state

        /* Check Params */
        if (!uid) {
            Swal.fire(utils.notification("Неизвестная ошибка! Пожалуйста, повторите попытку позже.", "error"))
            return
        }

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            reverseButtons: true,
            confirmButtonText: 'Удалить!',
            cancelButtonText: 'Отмена'
        }).then(result => {
            if (result.isConfirmed) {

                remove({ uid, id }).then(response => {
                    if (response.status === 200) {
                        const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

                        if (index > -1) {
                            let newData = data
                            newData.splice(index, 1)
                            this.setState({ data: newData })
                        }

                        Swal.fire(utils.notification("Валюта успешно удалена", "success"))
                    }
                    else if (response.status === 401 || response.status === 403) {
                        Swal.fire(utils.notification("Нет доступа!", "error"))
                    }
                    else if (response.status === 498) {
                        Swal.fire(utils.notification("Неверный ключ!", "error"))
                    }
                    else {
                        Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                    }
                }).catch(() => {
                    Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                })
            }
        })

    }


    /* Create Action */
    create = async () => {

        /* Fields */
        const { uid } = this.props
        const { data, currencies } = this.state

        /* Check Params */
        if (!uid) {
            Swal.fire(utils.notification("Неизвестная ошибка! Пожалуйста, повторите попытку позже.", "error"))
            return
        }

        const { value } = await Swal.fire({
            title: 'Добавить валюту',
            html:
                `   
                    <div class="swal-form">
                        <select id="currency_create">
                            <option value="0" selected disabled>Выберите валюту</option>
                            ${currencies.map(item => `<option value="${item.id}">${item.name}</option>`)}
                        </select>
                        <input type="number" id="max_create" class="swal-select" placeholder="Максимальная ставка">
                        <input type="number" id="min_create" class="swal-input" placeholder="Минимальная ставка">
                        <input type="number" id="maxPay_create" class="swal-input" placeholder="Минимальная выплата">
                        <input type="text" id="chips_create" class="swal-input" placeholder="Фишки (пишите через запятую)">
                    </div>
                `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#2196f3',
            reverseButtons: true,
            cancelButtonText: 'Отмена',
            confirmButtonText: 'Добавить',
            preConfirm: () => {

                const currencyID = document.getElementById('currency_create').value
                const max = document.getElementById('max_create').value
                const min = document.getElementById('min_create').value
                const maxPay = document.getElementById('maxPay_create').value
                const chips = document.getElementById('chips_create').value

                if (parseInt(currencyID) === 0) {
                    Swal.showValidationMessage(`Выберите валюту`)
                    return false
                }
                if (max === "" || max === undefined || max === null) {
                    Swal.showValidationMessage(`Введите максимальную ставку`)
                    return false
                }
                if (min === "" || min === undefined || min === null) {
                    Swal.showValidationMessage(`Введите минимальную ставку`)
                    return false
                }
                if (maxPay === "" || maxPay === undefined || maxPay === null) {
                    Swal.showValidationMessage(`Введите минимальную выплату`)
                    return false
                }
                if (chips === "" || chips === undefined || chips === null) {
                    Swal.showValidationMessage(`Введите список фишек`)
                    return false
                }

                return { currencyID, max, min, maxPay, chips }
            }
        })

        if (value) {
            create({ uid, ...value }).then(response => {
                if (response.status === 200) {
                    data.push(response.data)
                    this.setState({ data })
                    Swal.fire(utils.notification("Валюта успешно добавлена", "success"))
                }
                else if (response.status === 201) {
                    Swal.fire(utils.notification("Валюта уже добавлена", "error"))
                }
                else if (response.status === 401 || response.status === 403) {
                    Swal.fire(utils.notification("Нет доступа!", "error"))
                }
                else if (response.status === 498) {
                    Swal.fire(utils.notification("Неверный ключ!", "error"))
                }
                else {
                    Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                }
            }).catch(() => {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            })
        }
    }





    /* Edit Action */
    edit = async currency => {

        /* Fields */
        const { uid } = this.props
        const { data } = this.state

        /* Check Params */
        if (!uid) {
            Swal.fire(utils.notification("Неизвестная ошибка! Пожалуйста, повторите попытку позже.", "error"))
            return
        }

        const { value } = await Swal.fire({
            title: `Редактировать валюту (${currency.name})`,
            html:
                `   
                    <div class="swal-form">
                        <input value="${currency.max}" type="number" id="max_edit" class="swal-select" placeholder="Максимальная ставка">
                        <input value="${currency.min}" type="number" id="min_edit" class="swal-input" placeholder="Минимальная ставка">
                        <input value="${currency.maxPay}" type="number" id="maxPay_edit" class="swal-input" placeholder="Минимальная выплата">
                        <input value="${currency.chips}" type="text" id="chips_edit" class="swal-input" placeholder="Фишки (пишите через запятую)">
                    </div>
                `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#2196f3',
            reverseButtons: true,
            cancelButtonText: 'Отмена',
            confirmButtonText: 'Сохранить',
            preConfirm: () => {

                const max = document.getElementById('max_edit').value
                const min = document.getElementById('min_edit').value
                const maxPay = document.getElementById('maxPay_edit').value
                const chips = document.getElementById('chips_edit').value

                if (max === "" || max === undefined || max === null) {
                    Swal.showValidationMessage(`Введите максимальную ставку`)
                    return false
                }
                if (min === "" || min === undefined || min === null) {
                    Swal.showValidationMessage(`Введите минимальную ставку`)
                    return false
                }
                if (maxPay === "" || maxPay === undefined || maxPay === null) {
                    Swal.showValidationMessage(`Введите минимальную выплату`)
                    return false
                }
                if (chips === "" || chips === undefined || chips === null) {
                    Swal.showValidationMessage(`Введите список фишек`)
                    return false
                }

                return { max, min, maxPay, chips }
            }
        })

        if (value) {
            edit({ uid, id: currency.id, ...value }).then(response => {
                if (response.status === 200) {

                    const index = data.findIndex(e => parseInt(e.id) === parseInt(currency.id))

                    if (index > -1) {
                        let newData = data

                        newData[index].max = value.max
                        newData[index].min = value.min
                        newData[index].maxPay = value.maxPay
                        newData[index].chips = value.chips

                        this.setState({ data: newData })
                    }

                    Swal.fire(utils.notification("Валюта успешно добавлена", "success"))
                }
                else if (response.status === 401 || response.status === 403) {
                    Swal.fire(utils.notification("Нет доступа!", "error"))
                }
                else if (response.status === 498) {
                    Swal.fire(utils.notification("Неверный ключ!", "error"))
                }
                else {
                    Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
                }
            }).catch(() => {
                Swal.fire(utils.notification("Что-по пошло не так! Попробуйте позднее.", "error"))
            })
        }
    }


    render = () => {

        const { status, data } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="table--modal-content">
                {data.map((item, index) =>
                    <div className="table--currency-item" key={`${index}`}>

                        <div className="table--currency-item-head">

                            <div className="table--currency-item-text">
                                <p>{item.name}</p>
                                <span>{item.code}</span>
                            </div>

                            <div className="table--currency-item-buttons">
                                <div onClick={() => this.edit(item)} className="table--currency-edit-button">
                                    <img src="/images/pencil.png" alt="Pencil" />
                                </div>
                                <div onClick={() => this.remove(item.id)} className="table--currency-remove-button">
                                    <img src="/images/trash.png" alt="Trash" />
                                </div>
                            </div>

                        </div>

                        <div className="table--currency-element">
                            <p>Макс. ставка</p>
                            <span>{utils.convertor(item.max, item.symbol, item.isAfter)}</span>
                        </div>

                        <div className="table--currency-element">
                            <p>Мин. ставка</p>
                            <span>{utils.convertor(item.min, item.symbol, item.isAfter)}</span>
                        </div>

                        <div className="table--currency-element">
                            <p>Макс. выплата</p>
                            <span>{utils.convertor(item.maxPay, item.symbol, item.isAfter)}</span>
                        </div>

                        <div className="table--currency-element">
                            <p>Фишки</p>
                            <span>{item.chips}</span>
                        </div>

                    </div>
                )}


                <div onClick={() => this.create()} className="table--currency-create-button">Создать</div>

            </div>
        )
    }

}

export default Currency