import React, { Component } from "react"

/* Helpers */
import { utils } from '../helpers'

/* Modal */
import Modal from 'react-modal'

/* Moment */
import moment from 'moment'
import 'moment/locale/ru'

/* Moment Settings */
moment.locale("ru")



/* Component Transaction */
class Transaction extends Component {

    constructor() {
        super()

        this.state = {
            visible: false,
            active: null
        }
    }


    /* Draw Modal */
    _modal = () => {

        const { visible, active } = this.state

        return (
            <Modal
                isOpen={visible}
                onRequestClose={() => this.setState({ visible: false, active: null })}
                className={`transaction--modal`}
                overlayClassName="transaction--overlay"
                closeTimeoutMS={200}
            >

                <div className="transaction--modal-header">
                    <h2>Информация о транзакции</h2>
                    <div onClick={() => this.setState({ visible: false, active: null })} className="transaction--modal-close">
                        <img src="/images/close.png" alt="Close" />
                    </div>
                </div>

                {active &&
                    <div className="transaction--modal-information">

                        <div className="transaction--modal-information-item">
                            <div className="transaction--modal-information-text">Номер транзакции</div>
                            <div className="transaction--modal-information-name">{active.number}</div>
                        </div>

                        <div className="transaction--modal-information-item">
                            <div className="transaction--modal-information-text">Игрок</div>
                            <div className="transaction--modal-information-name">{active.player}</div>
                        </div>

                        <div className="transaction--modal-information-item">
                            <div className="transaction--modal-information-text">Название</div>
                            <div className="transaction--modal-information-name">{active.reason} {active.box ? `(BOX ${active.box})` : ''}</div>
                        </div>

                        <div className="transaction--modal-information-item">
                            <div className="transaction--modal-information-text">Тип</div>
                            <div className="transaction--modal-information-name">{active.type}</div>
                        </div>

                        <div className="transaction--modal-information-item">
                            <div className="transaction--modal-information-text">Время</div>
                            <div className="transaction--modal-information-name">{moment(active.createdAt).format("Do MMM YYYY, HH:mm:ss")}</div>
                        </div>

                        <div className="transaction--modal-information-item">
                            <div className="transaction--modal-information-text">Сумма</div>
                            <div className="transaction--modal-information-name">{utils.convertor(parseFloat(active.total), active.currency)}</div>
                        </div>

                        <div className="transaction--modal-information-item">
                            <div className="transaction--modal-information-text">Статус</div>
                            <div className="transaction--status">
                                {parseInt(active.status) === 1 ? <div className="success">Успешно</div> : null}
                                {parseInt(active.status) === 2 ? <div className="error">Ошибка</div> : null}
                            </div>
                        </div>

                        <div className="transaction--modal-information-item">
                            <div className="transaction--modal-information-text">Результат</div>
                            <div className="transaction--modal-information-name">{active.result}</div>
                        </div>

                    </div>
                }

            </Modal>
        )
    }


    render = () => {

        const { data, symbol } = this.props

        if (data && Array.isArray(data) && data.length > 0) {
            return (
                <div className="transaction--container">

                    <div className="transaction--container-title">Transactions</div>

                    <div className="transaction--boxes">

                        <div className="transaction--box">
                            <div className="transaction--key head">#</div>
                            <div className="transaction--number head">Номер транзакции</div>
                            <div className="transaction--name head">Название</div>
                            <div className="transaction--total head">Сумма</div>
                            <div className="transaction--status head">Статус</div>
                        </div>

                        {data.map((transaction, index) =>
                            <div onClick={() => this.setState({ visible: true, active: transaction })} className="transaction--box" key={`${index}`}>
                                <div className="transaction--key">{index + 1}</div>
                                <div className="transaction--number">{transaction.number}</div>
                                <div className="transaction--name">
                                    {transaction.reason}
                                    {transaction.box ? <div className={`transaction--name-box-${transaction.box}`}>{transaction.box} BOX</div> : ""}
                                </div>
                                <div className="transaction--total">
                                    {transaction.type === "debit" && <img src="/images/game/up.png" alt="Up" />}
                                    {transaction.type === "credit" && <img src="/images/game/down.png" alt="Up" />}
                                    {utils.convertor(transaction.total, symbol)}
                                </div>
                                <div className="transaction--status">
                                    {parseInt(transaction.status) === 1 ? <div className="success">Успешно</div> : null}
                                    {parseInt(transaction.status) === 2 ? <div className="error">Ошибка</div> : null}
                                </div>
                            </div>
                        )}
                    </div>

                    {this._modal()}

                </div>
            )
        }

        return <div />
    }

}

export default Transaction