import React, { Component } from "react"

/* Components */
import { Layout, Pagination, Tag } from '../../components'

/* REST API */
import { history } from '../../api/Profile'

/* Helpers */
import { utils } from '../../helpers'

/* Moment */
import moment from 'moment'
import 'moment/locale/ru'


/* Moment Settings */
moment.locale("ru")

/* Fields */
const PERCENTAGE = 95 / 5




/* Page History */
class History extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            count: 0,
            page: 1,
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load action */
    load = (page = 1, reload = false) => {

        this.setState({ page })

        if (reload) {
            this.setState({ status: 'loading' })
        }

        history(page).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.list, count: response.data.count })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: [], count: 0 })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: [], count: 0 })
            }
            else {
                this.setState({ status: "error", data: [], count: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], count: 0 })
        })
    }


    /* JSON Handler */
    jsonHandler = json => {
        try {

            const data = JSON.parse(json)

            const list = Object.entries(data)

            if (list.length === 0) {
                return "-"
            }

            return (
                <div className="page-json">
                    {list.map((item, index) => {
                        if (item[1] && item[0]) {
                            return (
                                <div className="page-json-row" key={`${index}`}>
                                    <div className="page-json-bold">{item[0]}</div>
                                    <div className="page-json-text">{item[1]}</div>
                                </div>
                            )
                        }
                        else {
                            return <div key={`${index}`} />
                        }
                    })}
                </div>
            )
        }
        catch {
            return "-"
        }

    }


    _time = time => {

        const datetime = utils.getTime(time)

        return (
            <div className="page-table-datetime">
                <div className="page-table-time">{datetime.time}</div>
                <div className="page-table-date">{datetime.date}</div>
            </div>
        )
    }


    render = () => {

        const { status, data, page, count } = this.state

        if (status !== "ok") {
            return <Layout status={status} reload={() => this.load(true)} />
        }

        return (

            <div className="page">

                <div className="page-header">
                    <h1>История входа</h1>
                </div>

                <div className="page-table">

                    <div className="page-table-header">
                        <div className="page-table-column number">#</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Устройство</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Браузер</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>IP адрес</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Страна, Город</div>
                        <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>Последняя активность</div>
                    </div>

                    {data.map((item, index) =>
                        <div className="page-table-body" key={`${index}`}>
                            <div className="page-table-column number">{(index + 1) * page}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>
                                {utils.checkValue(<Tag color={item.type === "mobile" ? "green" : "purple"}>{item.type}</Tag>, "-")}
                                {this.jsonHandler(item.device)}
                            </div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{utils.checkValue(item.browser, "-")}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{utils.checkValue(item.ip, "-")}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{utils.checkValue(item.country, "-")} {utils.checkValue(item.city, "")}</div>
                            <div className="page-table-column" style={{ width: `${PERCENTAGE}%` }}>{this._time(item.updatedAt)} </div>
                        </div>
                    )}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page)} />

                </div>


            </div>
        )
    }

}

export default History