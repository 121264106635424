import React, { Component } from "react"

/* REST API */
import { notes, create } from '../api/Note'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Components */
import Avatar from "./Avatar"

/* Moment */
import moment from "moment"

/* Sweetalert */
import Swal from "sweetalert2"

/* Constants */
import { utils } from "../helpers"



/* Component Notes */
class Notes extends Component {

    constructor() {
        super()

        this.state = {
            status: 'loading',
            data: [],
            visible: false,
            message: "",
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load action */
    load = (reload = false) => {

        const { uid, gameID } = this.props

        if (reload) {
            this.setState({ status: 'loading' })
        }

        notes({ uid, gameID }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, status: 'ok' })
            }
        })
    }

    /* Send action */
    send = () => {

        const { message, data } = this.state
        const { uid, gameID } = this.props

        if (message !== "") {
            create({ uid, gameID, message }).then(response => {
                if (response.status === 200) {
                    data.unshift(response.data)
                    this.setState({ data, message: "", waiting: false })
                }
                else {
                    Swal.fire(utils.notification("Не удалось создать заметку!", "error"))
                    this.setState({ waiting: false })
                }
            }).catch(() => {
                Swal.fire(utils.notification("Не удалось создать заметку!", "error"))
                this.setState({ waiting: false })
            })
        }

    }

    /* Submit action */
    submit = event => {
        event.preventDefault()
        this.send()
    }

    /* Load Messages */
    _messages = () => {

        const { status, data } = this.state

        if (status === 'loading') {
            return (
                <div className="notes--container-messages-loading">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="notes--container-messages-loading">
                    <span>Нет заметок</span>
                </div>
            )
        }

        return (
            <div className="notes--container-messages-box">
                {data.map((item, index) =>
                    <div className="notes--container-messages-item" key={`${index}`}>
                        <div className="notes--container-messages-user">
                            <Avatar />
                            <div className="notes--container-messages-user-text">
                                <div className="notes--container-messages-name">{item.name}</div>
                                <div className="notes--container-messages-time">{moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}</div>
                            </div>
                        </div>
                        <div className="notes--container-messages-text">{item.message}</div>
                    </div>
                )}
            </div>
        )
    }

    render = () => {

        const { data, message, visible, waiting } = this.state

        return (
            <div className={`notes--container ${visible ? 'open' : 'hidden'}`}>

                {/* Notes Toggle */}
                <div onClick={() => this.setState({ visible: !visible })} className="notes--container-toggle">
                    {data && Array.isArray(data) && data.length > 0 && <div className="notes--container-toggle-count">{data.length}</div>}
                    <div className="notes--container-toggle-text">Notes</div>
                    <img src="/images/game/writing.png" alt="Writing" />
                </div>

                {/* Notes */}
                <div className="notes--container-messages">

                    <div className="notes--container-messages-header">
                        <div className="notes--container-messages-header-text">Заметки</div>
                        <div onClick={() => this.setState({ visible: false })} className="notes--container-messages-header-close">
                            <img src="/images/close.png" alt="Close" />
                        </div>
                    </div>

                    {this._messages()}

                    <form onSubmit={this.submit} className="notes--container-messages-bottom">
                        <input placeholder="Напишите текст" value={message} onChange={event => this.setState({ message: event.target.value })} />
                        <div onClick={() => this.send()} className="notes--container-messages-button">
                            {waiting ? <LoadingOutlined /> : <img src="/images/game/send.png" alt="Send" />}
                        </div>
                    </form>

                </div>

            </div>
        )
    }

}

export default Notes