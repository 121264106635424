import React, { Component } from "react"

/* Components */
import { InnerLayout, Avatar } from '../../components'

/* REST API */
import { statistics } from '../../api/Player'

/* Helpers */
import { utils } from "../../helpers"

/* Games */
import { games } from '../../constants'

/* Moment */
import moment from 'moment'
import 'moment/locale/ru'

/* Moment Settings */
moment.locale("ru")



/* Widget Information */
class Information extends Component {

    constructor() {
        super()

        this.state = {
            statistics: null,
            status: "loading",
            tab: "",
            ava: ""
        }
    }

    componentDidMount = () => {
        this.load()
    }


    /* Load action */
    load = (reload = false) => {

        const { player } = this.props

        /* Reload Page */
        if (reload) {
            this.setState({ status: 'loading' })
        }

        /* Send to REST API */
        statistics(player).then(response => {
            if (response.status === 200) {
                const statistics = response.data
                const ava = utils.playerAva(statistics.information.isTester)
                this.setState({ status: "ok", statistics, ava })


                if (statistics.data && Array.isArray(statistics.data) && statistics.data.length > 0) {
                    this.setState({ tab: statistics.data[0].game })
                }
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", statistics: [] })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", statistics: [] })
            }
            else {
                this.setState({ status: "error", statistics: [] })
            }
        }).catch(() => {
            this.setState({ status: "network", statistics: [] })
        })

    }

    /* Get Game Text */
    getGame = key => {
        const index = games.data.findIndex(e => e.key === key)
        if (index > -1) {
            return games.data[index].name
        }
    }


    /* Draw Combinations */
    _combinations = () => {

        const { statistics, tab } = this.state

        if (statistics.data && Array.isArray(statistics.data) && statistics.data.length > 0) {
            const index = statistics.data.findIndex(e => e.game === tab)
            if (index > -1) {
                const combinations = statistics.data[index].combination
                if (combinations && Array.isArray(combinations) && combinations.length > 0) {
                    return (
                        <div className="player-information-combinations">
                            <h3>Комбинации</h3>
                            {combinations.map((combination, index) =>
                                <div className="player-information-combination" key={`${index}`}>
                                    <div className="player-information-combination-name">{combination.result}</div>
                                    <div className="player-information-combination-value">{combination.count}</div>
                                </div>
                            )}
                        </div>
                    )
                }
            }
        }
    }


    render = () => {

        const { status, statistics, tab, ava } = this.state

        if (status !== "ok") {
            return <InnerLayout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="player-information">

                {/* WIDGET */}
                <div className="player-information-widget">

                    {/* Player */}
                    <div className="player-information-widget-user">
                        <Avatar uri={ava} size={40} />
                        <div className="player-information-widget-user-text">
                            <div className="player-information-widget-user-code">{parseInt(statistics.information.isTester) > 0 ? "Тестировщик" : "Игрок"}</div>
                            <div className="player-information-widget-user-name">{utils.checkValue(statistics.information.firstName, "-")}</div>
                            <div className="player-information-widget-user-code">{utils.checkValue(statistics.information.player, "-")}</div>
                        </div>
                    </div>

                    {/* Games Count */}
                    <div className="player-information-widget-element">
                        <img src="/images/player/spades.png" alt="Spades" />
                        <div className="player-information-widget-element-text">
                            <div className="player-information-widget-element-name">Количество игр</div>
                            <div className="player-information-widget-element-value">{utils.checkValue(statistics.count, "0")}</div>
                        </div>
                    </div>

                    {/* AVG ANTE */}
                    <div className="player-information-widget-element">
                        <img src="/images/player/poker-chip.png" alt="Poker Chip" />
                        <div className="player-information-widget-element-text">
                            <div className="player-information-widget-element-name">Средняя ставка ANTE</div>
                            <div className="player-information-widget-element-value">{utils.convertor(parseInt(statistics.ante), utils.checkValue(statistics.information.symbol, "$"), utils.checkValue(statistics.information.isAfter, 0), " ")}</div>
                        </div>
                    </div>

                    {/* Total Time */}
                    <div className="player-information-widget-element">
                        <img src="/images/player/back-in-time.png" alt="Time" />
                        <div className="player-information-widget-element-text">
                            <div className="player-information-widget-element-name">Общее время</div>
                            <div className="player-information-widget-element-value">{utils.converTime(statistics.time)}</div>
                        </div>
                    </div>

                    {/* Debit */}
                    <div className="player-information-widget-element">
                        <img src="/images/player/increase.png" alt="Increase" />
                        <div className="player-information-widget-element-text">
                            <div className="player-information-widget-element-name">Debit</div>
                            <div className="player-information-widget-element-value">{utils.convertor(statistics.debit, utils.checkValue(statistics.information.symbol, "$"), utils.checkValue(statistics.information.isAfter, 0), " ")}</div>
                        </div>
                    </div>

                    {/* Credit */}
                    <div className="player-information-widget-element">
                        <img src="/images/player/decrease.png" alt="Decrease" />
                        <div className="player-information-widget-element-text">
                            <div className="player-information-widget-element-name">Credit</div>
                            <div className="player-information-widget-element-value">{utils.convertor(statistics.credit, utils.checkValue(statistics.information.symbol, "$"), utils.checkValue(statistics.information.isAfter, 0), " ")}</div>
                        </div>
                    </div>

                    {/* Total */}
                    <div className="player-information-widget-element">
                        <img src="/images/player/calculate.png" alt="Calculate" />
                        <div className="player-information-widget-element-text">
                            <div className="player-information-widget-element-name">Общая сумма</div>
                            <div className={`player-information-widget-element-value ${statistics.debit - statistics.credit >= 0 ? 'green' : 'red'}`}>{statistics.debit - statistics.credit >= 0 ? "+" : "-"} {utils.convertor(Math.abs(statistics.debit - statistics.credit), utils.checkValue(statistics.information.symbol, "$"), utils.checkValue(statistics.information.isAfter, 0), " ")}</div>
                        </div>
                    </div>

                </div>


                {/* Games */}
                <div className="player-information-games">

                    {/* Game Tabs */}
                    <div className="player-information-games-tabs">
                        {statistics.data.map((item, index) =>
                            <div onClick={() => this.setState({ tab: item.game })} className={`player-information-games-tab ${tab === item.game ? 'active' : 'disabled'}`} key={`${index}`}>{this.getGame(item.game)}</div>
                        )}
                    </div>

                    <div className="player-information-analitycs">
                        <div className="player-information-statistics">
                            Статистика пока недоступна
                        </div>
                        {this._combinations()}
                    </div>
                </div>

            </div>
        )
    }

}

export default Information