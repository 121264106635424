import React, { Component } from "react"



/* Page Dashboard */
class Dashboard extends Component {

    render = () => {
        return (
            <div className="page">
                <div className="page-header">
                    <h1>Dashboard</h1>
                </div>
            </div>
        )
    }

}

export default Dashboard