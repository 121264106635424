import React, { Component } from "react"

/* Widgets */
import { Layout } from '../Table'

/* REST API */
import { statistics } from '../../api/REP'
import { utils } from "../../helpers"


/* Widget REP Statistics */
class REPStatistics extends Component {

    constructor() {
        super()

        this.state = {
            data: null,
            status: 'loading',
            currency: { code: 'USD' }
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Game Information */
    load = (reload = false) => {

        /* Fields */
        const { uid } = this.props

        /* Validate */
        if (!uid) {
            this.setState({ status: 'error', data: null })
            return
        }

        /* Reload */
        if (reload) {
            this.setState({ status: "loading" })
        }

        /* Send request to REST API */
        statistics({ uid }).then(response => {
            if (response.status === 200) {
                console.log(response.data)
                const currencies = response.data.currencies
                if (currencies && Array.isArray(currencies) && currencies.length > 0) {
                    this.setState({ currency: currencies[0] })
                }
                this.setState({ status: "ok", data: response.data })
            }
            else if (response.status === 401 || response.status === 403) {
                this.setState({ status: "permission", data: null })
            }
            else if (response.status === 498) {
                this.setState({ status: "key", data: null })
            }
            else {
                this.setState({ status: "error", data: null })
            }
        }).catch(() => {
            this.setState({ status: "network", data: null })
        })

    }

    render = () => {

        const { status, data, currency } = this.state

        if (status !== "ok") {
            return (
                <div className='rep--game-inner'>
                    <Layout status={status} reload={() => this.load(true)} />
                </div>
            )
        }

        let statistic = null
        if (data && data.currencies && Array.isArray(data.currencies) && data.currencies.length > 0) {
            if (data && data.numbers && Array.isArray(data.numbers) && data.numbers.length > 0) {
                const index = data.numbers.findIndex(e => String(currency.code).toLowerCase() === String(e.code).toLowerCase())
                if (index > -1) {
                    statistic = data.numbers[index]
                }
            }
        }

        return (
            <div className="rep--game-statistics">

                {data && data.currencies && Array.isArray(data.currencies) && data.currencies.length > 0 &&
                    <div className="rep--game-statistics-currencies">
                        {data.currencies.map((item, index) =>
                            <div onClick={() => this.setState({ currency: item })} key={`${index}`} className={`rep--game-statistics-currency ${String(item.code).toLowerCase() === String(currency.code).toLowerCase() ? 'active' : 'default'}`}>
                                {item.name}
                            </div>
                        )}
                    </div>
                }

                {statistic &&
                    <div className="rep--game-numbers">
                        <div className="rep--game-number">

                            <div className="rep--game-number-text blue">За сегодня</div>

                            <div className="rep--game-number-element">
                                <img src="/images/game/increase.png" alt="DEBIT" />
                                <div className="rep--game-number-element-info">
                                    <div className="rep--game-number-element-text">Debit</div>
                                    <div className="rep--game-number-element-value">{utils.convertor(statistic.today.debit, currency.symbol, currency.isAfter)}</div>
                                </div>
                            </div>

                            <div className="rep--game-number-element">
                                <img src="/images/game/decrease.png" alt="DEBIT" />
                                <div className="rep--game-number-element-info">
                                    <div className="rep--game-number-element-text">Credit</div>
                                    <div className="rep--game-number-element-value">{utils.convertor(statistic.today.credit, currency.symbol, currency.isAfter)}</div>
                                </div>
                            </div>

                            <div className="rep--game-number-element">
                                <img src="/images/game/calculate.png" alt="DEBIT" />
                                <div className="rep--game-number-element-info">
                                    <div className="rep--game-number-element-text">Total</div>
                                    <div className="rep--game-number-element-value">
                                        {parseFloat(statistic.today.debit) > parseFloat(statistic.today.credit) && "+ "}
                                        {parseFloat(statistic.today.debit) < parseFloat(statistic.today.credit) && "- "}
                                        {utils.convertor(Math.abs(parseFloat(statistic.today.debit) - parseFloat(statistic.today.credit)), currency.symbol, currency.isAfter)}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="rep--game-number">

                            <div className="rep--game-number-text">За неделю</div>

                            <div className="rep--game-number-element">
                                <img src="/images/game/increase.png" alt="DEBIT" />
                                <div className="rep--game-number-element-info">
                                    <div className="rep--game-number-element-text">Debit</div>
                                    <div className="rep--game-number-element-value">{utils.convertor(statistic.week.debit, currency.symbol, currency.isAfter)}</div>
                                </div>
                            </div>

                            <div className="rep--game-number-element">
                                <img src="/images/game/decrease.png" alt="DEBIT" />
                                <div className="rep--game-number-element-info">
                                    <div className="rep--game-number-element-text">Credit</div>
                                    <div className="rep--game-number-element-value">{utils.convertor(statistic.week.credit, currency.symbol, currency.isAfter)}</div>
                                </div>
                            </div>

                            <div className="rep--game-number-element">
                                <img src="/images/game/calculate.png" alt="DEBIT" />
                                <div className="rep--game-number-element-info">
                                    <div className="rep--game-number-element-text">Total</div>
                                    <div className="rep--game-number-element-value">
                                        {parseFloat(statistic.week.debit) > parseFloat(statistic.week.credit) && "+ "}
                                        {parseFloat(statistic.week.debit) < parseFloat(statistic.week.credit) && "- "}
                                        {utils.convertor(Math.abs(parseFloat(statistic.week.debit) - parseFloat(statistic.week.credit)), currency.symbol, currency.isAfter)}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="rep--game-number">

                            <div className="rep--game-number-text">За месяц</div>

                            <div className="rep--game-number-element">
                                <img src="/images/game/increase.png" alt="DEBIT" />
                                <div className="rep--game-number-element-info">
                                    <div className="rep--game-number-element-text">Debit</div>
                                    <div className="rep--game-number-element-value">{utils.convertor(statistic.month.debit, currency.symbol, currency.isAfter)}</div>
                                </div>
                            </div>

                            <div className="rep--game-number-element">
                                <img src="/images/game/decrease.png" alt="DEBIT" />
                                <div className="rep--game-number-element-info">
                                    <div className="rep--game-number-element-text">Credit</div>
                                    <div className="rep--game-number-element-value">{utils.convertor(statistic.month.credit, currency.symbol, currency.isAfter)}</div>
                                </div>
                            </div>

                            <div className="rep--game-number-element">
                                <img src="/images/game/calculate.png" alt="DEBIT" />
                                <div className="rep--game-number-element-info">
                                    <div className="rep--game-number-element-text">Total</div>
                                    <div className="rep--game-number-element-value">
                                        {parseFloat(statistic.month.debit) > parseFloat(statistic.month.credit) && "+ "}
                                        {parseFloat(statistic.month.debit) < parseFloat(statistic.month.credit) && "- "}
                                        {utils.convertor(Math.abs(parseFloat(statistic.month.debit) - parseFloat(statistic.month.credit)), currency.symbol, currency.isAfter)}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default REPStatistics